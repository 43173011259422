import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from "@emotion/react";

const GigSkills = (props) => {
  const {classes, skills} = props;
  const theme = useTheme();
  let idx = 0;
  return (
    <ul style={{margin: 0, padding: 0, color: theme.palette.primary.light}}>
      {skills.map(s => {
        if (idx === 0) {
          idx++;
          return (<React.Fragment key={s}><li style={{display: 'inline'}}><small>{s}</small></li></React.Fragment>);
        } else {
          return (<React.Fragment key={s}> {'\u00B7'} <li style={{display: 'inline'}}><small>{s}</small></li></React.Fragment>);
        }
      })}
    </ul>
  );
};

GigSkills.propTypes = {
  skills:  PropTypes.array.isRequired
};

export default GigSkills;