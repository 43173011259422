import React from "react";
import PropTypes from 'prop-types';

import { Typography, Card, CardContent } from "@mui/material";

const ResEducation = (props) => {

  const {classes, education} = props;

  return (
    <Card sx={{margin: (theme) => theme.spacing(3)}}>
      <CardContent>
        { education.map(e => {
          return (
            <React.Fragment key="{e.name}">
              <Typography variant="h6">{e.name}</Typography>
              <ul>
                { e.degrees.map(d => {
                  return (<li key={d.name}>
                    <Typography sx={{color: (theme) => theme.palette.primary.light}} component="div" variant="body1">{d.name}</Typography>
                    <Typography component="div" variant="body2">{d.what}</Typography>
                  </li>);
                })}
              </ul>
            </React.Fragment>
          )
        })}
      </CardContent>
    </Card>
  );
};

ResEducation.propTypes = {
  education:  PropTypes.array.isRequired
};

export default ResEducation;