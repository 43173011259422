import React from "react";
import PropTypes from 'prop-types';
import Gig from './Gig.js';


const ResEmployment = (props) => {
  const {employment} = props;
  return (
    <>      
      {employment.map(gig => {
        return (
          <Gig key={gig.when} gig={gig} />
        );
      })}
    </>
  );
};


ResEmployment.propTypes = {
  employment:  PropTypes.array.isRequired
}
export default ResEmployment;