import React from "react";
import PropTypes from 'prop-types';
import { AppBar, Grid, Typography, Link  } from "@mui/material";


const ResHeader = (props) => {
  const {doc} = props;
  return (
    <AppBar sx={{position: 'relative'}}>
      <div sx={{flexGrow: 1, padding: (theme) => theme.spacing(0)}}>
        <Grid container spacing={0}>
          <Grid item md={2} xs={12} sx={{display: 'flex', textAlign: 'center', alignItems: 'center'}}>
            <Typography component="div" variant="h6" color="inherit" sx={{padding: (theme) => theme.spacing(1), width: '100%', textAlign: 'center'}}  >
              {doc.name}
            </Typography>
          </Grid>
          <Grid item md={10} xs={12}>
            <Typography variant="body1" color="inherit" sx={{padding: (theme) => theme.spacing(1)}}>
              {doc.banner}
            </Typography>
          </Grid>
          <Grid item md={2} xs={false} >           
            <Typography sx={(theme) => ({[theme.breakpoints.down('sm')]:{display: 'none'}, textAlign: 'center', background: theme.palette.primary.dark})} component="div" variant="body1" color="inherit" noWrap>
            &nbsp;
            </Typography>
          </Grid>
          <Grid item sx={{background: (theme) => theme.palette.primary.dark}} md={8} xs={12} >
            <Typography style={{textAlign: 'center'}} component="div" variant="body2" color="inherit" noWrap>
            aris.green@elucidev.com &bull; 623.810.3010
            </Typography>
          </Grid>
          <Grid item sx={{background: (theme) => theme.palette.primary.dark}}  xs={12} md={2} >
            <Typography style={{textAlign: 'center'}} component="div" variant="body2">
              <Link sx={{color: (theme) => theme.palette.secondary.light}} component="a" href="/classic/">classic</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link sx={{color: (theme) => theme.palette.secondary.light}} component="a" href="/files/resume.pdf">.pdf</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link sx={{color: (theme) => theme.palette.secondary.light}} component="a" href="/files/resume.docx">.docx</Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </AppBar>    
  );
};

ResHeader.propTypes = {
  doc:  PropTypes.object.isRequired
}

export default ResHeader;