
import doc from './data/resume.json';
import Resume from './Resume';

const ResumeContainer = (props) => {
  console.log(` this is ${doc}`);
  return (
    <Resume doc={doc} />
  )
};

export default ResumeContainer;
