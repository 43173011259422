import React from "react";
import PropTypes from 'prop-types';

import ResSkills from './ResSkills';
import ResEmployment from "./ResEmployment";
import ResEducation from "./ResEducation";

import {Tab, Tabs} from '@mui/material';

import SwipeableViews from 'react-swipeable-views-react-18-fix';
import ResLink from "./ResLink";

const ResumeSections = [
  (props) => {return (<ResSkills skills={props.doc.skills} />)},
  (props) => {return (<ResEmployment employment={props.doc.employment} />)},
  (props) => {return (<ResEducation education={props.doc.education} />)},
  (props) => {return (<ResLink />)},  
];


const styles = {
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: 0,
  },
  mobileStepper: {
    flexShrink: 0,
  },

  view: {
    flexGrow: 1,
    overflowY: "scroll",
    minHeight: '100%',
  },
};

class ResumeStepper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {activeStep: 0};
  }

  handleChange = (event, value) => {
    this.setState({activeStep: value});
  };

  handleChangeIndex = (index) => {
    this.setState({activeStep: index});
    window.scroll({top: 0, behavior: "smooth"});
  }


  render() {
    const { activeStep } = this.state;

    return (
      <div sx={styles.root}>
        <Tabs textColor='primary' 
              sx={(theme) => ({[theme.breakpoints.down('md')]:{ ...theme.typography.caption}})} 
              value={activeStep} 
              indicatorColor="secondary"
              variant="fullWidth" onChange={this.handleChange}>
          <Tab sx={styles.tabs} label="Skills" />
          <Tab sx={styles.tabs} label="Employment" />
          <Tab sx={styles.tabs} label="Education" />
          <Tab sx={styles.tabs} label="Link" />
        </Tabs>
        <SwipeableViews index={activeStep} onChangeIndex={this.handleChangeIndex} >
          <div sx={styles.view}>
            {ResumeSections[0](this.props)}
          </div>      
          <div>
            {ResumeSections[1](this.props)}
          </div>
          <div>
            {ResumeSections[2](this.props)}
          </div>
          <div>
            {ResumeSections[3](this.props)}
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

ResumeStepper.propTypes = {
};

export default ResumeStepper;
