import React from "react";
import PropTypes from 'prop-types';
// import Typography from '@material-ui/core/Typography';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { Typography, Card, CardContent } from '@mui/material';

import GigSkills from './GigSkills';
import GigBullets from './GigBullets';

class Gig extends React.Component {

  getTextOrLink(amialink) {
    if (typeof(amialink) == 'string') {
      return amialink;
    }
    return <a key={amialink.url} href={amialink.url}>{amialink.text}</a>;
  }


  getTextAndLInks(list, delim) {
    return list.map(s => this.getTextOrLink(s));
  }

  render() {
    const {classes, gig} = this.props;
    return (
      <Card sx={{margin: (theme) => theme.spacing(2)}}>
        <Typography component="div">
        <CardContent>
          <Typography variant="h6" sx={{display: 'inline'}}>{this.getTextOrLink(gig.place)}</Typography>{" \u00a0"}  
          <Typography variant="body2" sx={{display: 'inline'}} component='span'>{gig.where}</Typography>{" \u00a0"} 
          <Typography variant="body1" sx={{display: 'inline'}}><i>{gig.when}</i></Typography>
          <Typography>{[...this.getTextAndLInks(gig.description)]}</Typography>
          <GigSkills skills={gig.skillitems} />
          <GigBullets bullets={gig.bullets} />
        </CardContent>

        </Typography>
      </Card>
    );
  }
}

Gig.propTypes = {
  gig:  PropTypes.object.isRequired
}

export default Gig;
