import React from "react";
import PropTypes from 'prop-types';

import { Paper, Table, TableBody, TableRow, TableCell, Typography } from "@mui/material";

const ResSkills = (props) => {
  const {skills} = props;
  return (
    <Paper sx={{margin: (theme) => theme.spacing(3)}} >
      <Table>
        <TableBody>
          {skills.map(row => {
            return (
              <TableRow key={row.cat}>
                <TableCell>
                  <Typography component='span' sx={(theme) => ({...theme.typography.h6, [theme.breakpoints.down('md')]: {...theme.typography.subtitle1}})}>
                    {row.cat}
                  </Typography>
                </TableCell>
                <TableCell>
                  <span>
                    {row.items.join(" \u00b7 ")}
                  </span>
                </TableCell>
              </TableRow>
              );
          })}          
        </TableBody>
      </Table>
    </Paper>
  );
}

ResSkills.propTypes = {
  skills:  PropTypes.array.isRequired
}

export default ResSkills;
