import React from "react";
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import ResHeader from './ResHeader';
import ResumeStepper from "./ResumeStepper";



const Resume = (props) => {
  const {classes} = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <div sx={{flexShrink: 0}}>
        <ResHeader doc={props.doc} />
      </div>
      <ResumeStepper doc={props.doc} />
    </React.Fragment>
  )
};

Resume.propTypes = {
  doc:  PropTypes.object.isRequired
}

export default Resume;