import React from "react";
import PropTypes from 'prop-types';

const GigBullets = (props) => {
  const {bullets} = props;
  return (
    <ul>
      { bullets.map(b => <li key={b}>{b}</li>) }
    </ul>
  );
};

GigBullets.propTypes = {
  bullets:  PropTypes.array.isRequired
};

export default GigBullets;