import React from 'react';

import { deepPurple, lightGreen } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';

import ResumeContainer from "./ResumeContainer";
import './App.css';

const theme = createTheme({
  palette: {
    primary: deepPurple,
    secondary: lightGreen,
  },
  typography: {
    useNextVariants: true,
  },  
  status: {
    danger: 'orange',
  },
});


function App() {
  return (
    <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <ResumeContainer />
    </ThemeProvider>
    </>
  );  
}

export default App;
