import React from "react";

// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import {Card, CardContent} from '@mui/material';

import QrCode from './svg/qr-resume-level533.svg';

const ResLink = (props) => {

  return (<Card sx={{margin: (theme) => theme.spacing(3)}} >
    <CardContent sx={{textAlign: 'center'}} >
      <img src={QrCode} alt="qr code link" />
    </CardContent>
  </Card>);
};

// ResLink.propTypes = {
//   education:  PropTypes.array.isRequired
// };

export default ResLink;